import { useTanstackTableVariants } from '@ui/components/TanstackTable'
import { createColumnHelper } from '@tanstack/vue-table'
import type {
  IDashboardResultTopLesseesItem,
  IDashboardResultTopLessorsItem,
} from '~/composables/useAPI/useApiDashboard'
import { createDashboardReportURI } from '../utils'
import { DashboardQuery } from '@dashboard'

export type LessorOrLesseeItem =
  | IDashboardResultTopLessorsItem
  | IDashboardResultTopLesseesItem

export const useLandlordsWidgetTable = (
  by: MaybeRefOrGetter<
    | DashboardQuery.TOP_LESSEES
    | DashboardQuery.TOP_LESSEES_BY_RENT
    | DashboardQuery.TOP_LESSORS
    | DashboardQuery.TOP_LESSORS_BY_RENT
  >,
) => {
  const { isLandlord } = useAuth()
  const { unitSize, currentDivision } = useDivisions()
  const companyId = getCurrentDivisionId()

  const createCurrency = (amount: number): string => {
    //TODO: fix type error
    const localization = currentDivision.value.localization

    const locale = localization.locale ?? 'en-AU'
    const currency = localization.currency?.id || 'AUD'
    const currencyDisplay = 'narrowSymbol'

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay,
    }).format(amount)
  }

  // COLUMNS
  const columnHelper = createColumnHelper<LessorOrLesseeItem>()
  const columns = computed(() => {
    const isByRent =
      toValue(by) === DashboardQuery.TOP_LESSEES_BY_RENT ||
      toValue(by) === DashboardQuery.TOP_LESSORS_BY_RENT

    return [
      columnHelper.accessor(
        (row) => {
          if (isLandlord.value) {
            return (row as IDashboardResultTopLesseesItem).tenant
          }

          return (row as IDashboardResultTopLessorsItem).landlord
        },
        {
          id: 'name',
          header: () => (isLandlord.value ? 'Tenant' : 'Landlord'),
        },
      ),

      isByRent
        ? columnHelper.accessor('totalCurrentRent', {
            id: 'totalCurrentRent',
            header: 'Rent',
            cell: ({ getValue }) => {
              const totalRent = getValue() as number
              if (totalRent === undefined) return
              return createCurrency(totalRent)
            },
          })
        : columnHelper.accessor('totalArea', {
            id: 'totalArea',
            header: () => `Area (${unitSize.value?.text})`,
            cell: ({ getValue }) => {
              const totalArea = getValue() as number
              if (totalArea === undefined) return
              return new Intl.NumberFormat().format(totalArea)
            },
          }),

      columnHelper.accessor('percentage', {
        id: 'percentage',
        header: 'Portfolio Composition',
        cell: ({ getValue }) => {
          const percentage = getValue()
          if (percentage === undefined) return
          return `${new Intl.NumberFormat().format(percentage * 100)}%`
        },
      }),
    ]
  })

  // ROW CLICK
  const router = useRouter()
  const onClickRow = (obj: LessorOrLesseeItem, templateId?: string) => {
    //TODO: get the templateId
    const path = createDashboardReportURI(companyId, obj.link, templateId)
    if (path) {
      router.push(path)
    }
  }

  // VARIANT
  const variant = useTanstackTableVariants({
    slots: {
      base: 'scrollbar-track-piece:bg-gray-800 h-full overflow-y-auto scroll-smooth pr-6 scrollbar-corner:bg-gray-800 scrollbar-track:bg-gray-800',
      wrapper: 'bg-transparent pb-16',
      theadTr: 'bg-gray-800',
      theadTh:
        'text-center text-xs [&:nth-last-child(1)]:!px-1 [&:nth-last-child(1)>span]:inline [&:nth-last-child(1)>span]:p-0 [&:nth-last-child(2)]:!px-1 [&:nth-last-child(2)>span]:inline [&:nth-last-child(2)>span]:p-0',
      tbody: 'divide-gray-750',
      tbodyTd:
        'text-sm [&:nth-last-child(1)>span]:text-center [&:nth-last-child(1)>span]:p-0 [&:nth-last-child(2)>span]:text-center [&:nth-last-child(2)>span]:p-0',
    },
    variants: {
      hoverable: {
        true: {
          tbodyTr: 'hover:text-white',
        },
      },
      stripedRows: {
        false: {
          tbodyTr: 'bg-gray-800',
        },
      },
    },
  })

  return { columns, variant, onClickRow }
}
