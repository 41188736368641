<script setup lang="ts">
import { createDashboardPortfolioURI, DashboardQuery } from '@dashboard'
import type { TanstackTableColumn } from '@ui/components/TanstackTable'
import { type LessorOrLesseeItem, useLandlordsWidgetTable } from '@dashboard'
import { Product } from '~/composables/useAPI/types'

const companyId = getCurrentDivisionId()

type ShowByLandlord =
  | DashboardQuery.TOP_LESSEES
  | DashboardQuery.TOP_LESSEES_BY_RENT
type ShowByTenant =
  | DashboardQuery.TOP_LESSORS
  | DashboardQuery.TOP_LESSORS_BY_RENT
type ShowBy = ShowByLandlord | ShowByTenant

const { isLandlord } = useAuth()
const { $can } = useNuxtApp()

const byRentValue = computed(() =>
  isLandlord.value
    ? DashboardQuery.TOP_LESSEES_BY_RENT
    : DashboardQuery.TOP_LESSORS_BY_RENT,
)
const byAreaValue = computed(() =>
  isLandlord.value ? DashboardQuery.TOP_LESSEES : DashboardQuery.TOP_LESSORS,
)

const showBy = ref<ShowBy>(
  $can('product', Product.LEASE_MANAGER)
    ? byRentValue.value
    : byAreaValue.value,
)

const showByText = {
  [DashboardQuery.TOP_LESSEES]: 'area',
  [DashboardQuery.TOP_LESSEES_BY_RENT]: 'rent',
  [DashboardQuery.TOP_LESSORS]: 'area',
  [DashboardQuery.TOP_LESSORS_BY_RENT]: 'rent',
} satisfies Record<ShowBy, string>

const { data, isPending } = useApiDashboard(companyId, showBy)

const header = computed(() =>
  isLandlord.value ? 'Top Lessees' : 'Top Lessors',
)

const total = computed(() => {
  const totalAreaSum = data.value?.results?.data.reduce(
    (acc, item) => acc + (item as any).totalArea,
    0,
  )
  const totalCurrentRentSum = data.value?.results?.data.reduce(
    (acc, item) => acc + (item as any).totalCurrentRent,
    0,
  )
  const isByRent = [
    DashboardQuery.TOP_LESSEES_BY_RENT,
    DashboardQuery.TOP_LESSORS_BY_RENT,
  ].includes(showBy.value)
  // const percentageSum = data.value?.results?.data.reduce(
  //   (acc, item) => acc + item.percentage,
  //   0,
  // )
  const percentageSum = 1

  // Create the total object
  return {
    landlord: isLandlord.value ? undefined : 'Total',
    tenant: isLandlord.value ? 'Total' : undefined,
    totalArea: !isByRent ? totalAreaSum : undefined,
    totalCurrentRent: isByRent ? totalCurrentRentSum : undefined,
    percentage: percentageSum,
  }
})

const hasData = computed(() => {
  return Object.entries(data.value?.results ?? {}).length > 0
})

const { columns, variant, onClickRow } = useLandlordsWidgetTable(showBy)
</script>

<template>
  <DashboardWidget
    :header="header"
    :subheader="`(by ${showByText[showBy]})`"
    :loading="isPending"
    :is-empty="!hasData"
    class="relative overflow-hidden !p-0"
    :ui="{
      header: 'px-6 mt-4',
      secondaryTitle: 'px-6',
      loading: 'px-6',
      empty: 'px-6',
      content: 'pl-6',
    }"
  >
    <template #secondaryTitle>
      <DashboardToggleRentArea
        v-model="showBy"
        :rent-value="byRentValue"
        :area-value="byAreaValue"
      />
    </template>

    <template #additional>
      <DashboardMissingData
        v-if="data?.missingData"
        v-bind="data.missingData"
      />
    </template>

    <!-- TODO: Migrate to SimpleTable -->
    <TanstackTable
      :columns="columns"
      :data="[...data?.results?.data, total]"
      :aria-describedby="header"
      :loading="isPending"
      clickable
      :sticky="{ scroller: null }"
      :variant="variant"
      @click:row="onClickRow($event, data?.templateId)"
    >
      <template #item="{ value }: TanstackTableColumn<LessorOrLesseeItem>">
        <span class="line-clamp-2">
          {{ value ?? '-' }}
        </span>
      </template>
    </TanstackTable>

    <!-- <div
      class="bg-gray-750/90 text-gray-150 absolute bottom-2 left-6 right-10 flex flex-row rounded-xl px-5 py-4 text-sm font-medium backdrop-blur-2xl backdrop-saturate-[180%] before:absolute before:-bottom-2 before:left-0 before:right-0 before:h-2 before:bg-gray-800/90 before:backdrop-blur-2xl before:backdrop-saturate-[180%] before:content-['']"
    >
      <div class="flex-auto">{{ total.landlord }}</div>
      <div class="w-[130px] text-center">
        {{ total.totalArea ?? 'N/A' }}
      </div>
      <div class="w-[80px] text-center">{{ total.percentage ?? 'N/A' }}</div>
    </div> -->
  </DashboardWidget>
</template>

<style lang="postcss" scoped>
:deep(table) {
  tbody {
    tr {
      &:last-child {
        @apply overflow-hidden rounded-xl px-5 py-4;

        td {
          @apply bg-gray-700;
        }

        td:first-child {
          @apply rounded-l-xl;
        }

        td:last-child {
          @apply overflow-hidden rounded-r-xl;
        }
        span {
          @apply text-base font-semibold;
        }
      }
    }
  }
}
</style>
